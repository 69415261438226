import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

interface CarouselImageProps {
  items: string[];
}

const CarouselImage: React.FC<CarouselImageProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-image-container">
      <button
        onClick={goToPrevSlide}
        className="carousel-image-button prev-button"
      >
        <FaAngleLeft />
      </button>
      <div className="carousel-content">
        <img
          src={items[currentIndex]}
          alt="Carousel Image"
          className="imageGrid"
        />
        <div className="carousel-image-pagination">
          {items.map((_, index) => (
            <span
              key={index}
              className={`pagination-dot ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>
      <button
        onClick={goToNextSlide}
        className="carousel-image-button next-button"
      >
        <FaAngleRight />
      </button>
    </div>
  );
};

export default CarouselImage;
