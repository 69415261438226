import React from "react";
import { MdWhatsapp } from "react-icons/md";

interface Props {
  isMobile: boolean;
}

const FloatingButton: React.FC<Props> = ({isMobile}) => {
    const redirectWhatsApp = () => {
      window.open("https://api.whatsapp.com/send?phone=55997296001", "_blank");
    };
    return (
      <button className="floatingButton" onClick={redirectWhatsApp}>
        {!isMobile ? <MdWhatsapp size={60} /> : <MdWhatsapp size={45}/>}
      </button>
    );
};

export default FloatingButton;
