import React, { useState } from 'react';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

interface CarouselProps {
  items: string[];
}

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  return (
    <div className="carousel-container">
      <button onClick={goToPrevSlide} className="carousel-button">
        <SlArrowLeft />
      </button>
      <div className="carousel-content">
        <p className="carousel-text">{items[currentIndex]}</p>
        <div className="carousel-pagination">
          {items.map((_, index) => (
            <span
              key={index}
              className={`pagination-dot ${
                index === currentIndex ? "active" : ""
              }`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
      <button onClick={goToNextSlide} className="carousel-button">
        <SlArrowRight />
      </button>
    </div>
  );
};

export default Carousel;
