import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Home } from "./pages/home/Home";
import "./styles.css"

const App = () => (
  <React.StrictMode>
    <Toaster />
    <BrowserRouter>
      <Home />
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<App />, document.getElementById("root"));
