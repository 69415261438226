import React, { useEffect } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import { Vector } from "ol/source";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { Style, Icon, Text, Fill, Stroke } from "ol/style";
import imageIcon from "../images/iconMapMarker.png";
import { defaults as defaultInteractions } from "ol/interaction";

const createTextStyles = (
  texts: string[],
  baseOffsetY: number,
  offsetStep: number
) => {
  return texts.map((text, index) => {
    return new Text({
      text: text,
      offsetY: baseOffsetY - index * offsetStep,
      textAlign: "center",
      fill: new Fill({
        color: "black",
      }),
      padding: [5, 10, 5, 10],
      font: "bold 18px Arial",
      stroke: new Stroke({
        color: "white",
        width: 4,
        lineJoin: "round",
      }),
    });
  });
};

const MapComponent: React.FC = () => {
  useEffect(() => {
    const center3857 = fromLonLat([-53.82056793217942, -29.69072055483272]);

    const textLines = ["Acheronta", "Clínica"];
    const textStyles = createTextStyles(textLines, -45, 20);

    const map = new Map({
      target: "map",
      interactions: defaultInteractions({
        doubleClickZoom: false,
        mouseWheelZoom: false,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorLayer({
          source: new Vector({
            features: [
              new Feature({
                geometry: new Point(center3857),
              }),
            ],
          }),
          style: (feature) => [
            new Style({
              image: new Icon({
                anchor: [0.5, 1],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                src: imageIcon,
                scale: 0.5,
              }),
            }),
            ...textStyles.map((textStyle) => new Style({ text: textStyle })),
          ],
        }),
      ],
      view: new View({
        center: center3857,
        zoom: 17,
      }),
    });

    return () => {
      map.dispose();
    };
  }, []);

  return (
    <div  className="styleMapComponent">
      <div
        id="map"
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default MapComponent;
