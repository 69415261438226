import React, { useEffect, useState } from "react";
import image from "../../images/imageProfile.jpeg";
import imageEndereco1 from "../../images/imageEndereco1.jpeg";
import imageEndereco2 from "../../images/imageEndereco2.jpeg";
import imageEndereco3 from "../../images/imageEndereco3.jpeg";
import imageEndereco4 from "../../images/imageEndereco4.jpeg";
import imageSubjetividade from "../../images/imagemMeninoEspelho.png";
import imagePsicanalise from "../../images/imagePsicanaliseCut.jpeg";
import imageBrasil from "../../images/imageBrasil.jpg";
import imagePsicoterapia from "../../images/imagePsicoterapia.jpg";
import logoBranco from "../../images/logoNatiBranco.png";
import logoPreto from "../../images/logoNatiPreto.png";
import imageFundo from "../../images/imageFundo.png";
import imageFundoMobile from "../../images/imageFundoMobile.png";
import logoMarrom from "../../images/logoNatiMarrom.png";
import { FaInstagram } from "react-icons/fa6";
import "bootstrap/dist/css/bootstrap.min.css";
import MapComponent from "../../components/Map";
import { MdWhatsapp } from "react-icons/md";
import FloatingButton from "../../components/FloatingButton";
import Carousel from "../../components/Carousel";
import ArticleModal from "../../components/ArticleModal";
import CarouselImage from "../../components/CarouselImage";
import { isMap } from "util/types";

export function Home(): JSX.Element {
  const redirectWhatsApp = () => {
    window.open("https://api.whatsapp.com/send?phone=55997296001", "_blank");
  };

  const redirectInstagram = () => {
    window.open("https://www.instagram.com/psinataliw/", "_blank");
  };

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isModalOneOpen, setIsModalOneOpen] = useState(false);
  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
  const [isModalThreeOpen, setIsModalThreeOpen] = useState(false);

  const articleText = `
    PSICANÁLISE UMA BREVE NOÇÃO
    A Psicanálise surge no século XIX a partir das elaborações teóricas de Sigmund Freud acerca do psiquismo humano. O médico vienense cria o que é considerado uma teoria, um método de investigação e uma prática profissional. O ponto central da teoria reside no esclarecimento de que o homem é regido por forças que escapam à consciência. Grande parte do conteúdo psíquico do sujeito permanece em seu inconsciente.
    
    As elaborações inauguradas pela teoria psicanalítica demarcam um outro modo de pensar a relação do sujeito consigo e sua relação com o mundo. Freud descentrou a razão: o inconsciente é a Outra Cena que revela que o ser humano não possui domínio de si mesmo. A existência de um pensamento inconsciente pressupõe o fato de que existem afetos e memórias reprimidas, os quais mais tarde podem se traduzir em sintomas psicopatológicos.
    
    Existir na cultura, enquanto sujeito, prevê um mal-estar inevitável. O corpo é a fonte do sofrimento, visto que é barrado em seus prazeres totais e impossibilitado de eliminar o desprazer por completo. Há um desconsolo que toma a dimensão social, individual e coletivamente, em função de certos limites que a vida em civilização nos impõe. Desse modo, a Psicanálise torna-se um importante instrumento para análise e compreensão dos fenômenos sociais.
    
    A clínica contemporânea coloca em pauta novas formas de sofrimento, condizentes com a lógica de vida atual. Os sintomas psíquicos são estruturalmente atravessados pelos processos de socialização do sujeito e sua historicidade. É a partir desse laço com o social que a teoria psicanalítica convoca o sujeito a questionar sua responsabilidade frente ao seu próprio sofrimento. Que narrativa está por trás do mal-estar do qual o paciente se queixa? Que significados ele atribui à sua história de vida?
    
    A escuta e intervenção sob o viés psicanalítico caminha em direção à elaboração de novos significados e reposicionamento do sujeito em sua história. É fundamental que o sujeito seja entendido para além de seu diagnóstico, para que assim seja possível trabalhar a partir de seu sintoma buscando o alívio do sofrimento e ressignificação da dor.

    Bibliografia utilizada:

    FREUD, S. (1930 [1929]) O mal-estar na civilização. Edição Standard Brasileira das Obras Completas de Sigmund Freud, vol. XXI. Rio de Janeiro: Imago, 1996. 
    COUTINHO JORGE, M.A; FERREIRA, N.P. Freud, Criador da Psicanálise. Coleção Psicanálise Passo-a-passo, 3ª ed. Rio de Janeiro: Jorge Zahar Ed., 2010.
  `.trim();

  const articleTextTwo = `
    CORPO E SUBJETIVIDADE NA TEORIA DE JACQUES LACAN
    Ao nascer, o sujeito apresenta uma prematuração específica que está para além da formação fisiológica. Há uma incompletude da anatomia cerebral, a coordenação motora e a captação sensorial são escassas, assim como há uma falta do esquema mental de unidade do corpo. O bebê não vê seu corpo como uma unidade, não há distinção entre interno e externo, individualidade e alteridade. Existe uma confusão entre o próprio corpo e o corpo da mãe, por exemplo, como se estivessem fusionados.
    
    A esta fase, Lacan dá o nome de Estádio do Espelho, na qual surgem os primeiros processos de identificação e individualização. É por volta do 18º mês de vida que, a partir desse processo, se inicia a constituição do “eu” do sujeito. O corpo, nesse sentido, é a peça central por onde transita a pulsão e o qual recebe as primeiras marcas de uma história. Em um primeiro tempo, a criança olha para sua imagem no espelho e recebe uma imagem combinada, que a princípio é de um estranho (um outro). Na sequência, acontece uma experiência de transitivismo (fragmentação do corpo da criança, ausência de unidade). Por terceiro, há a realização da imagem virtual e da imagem real, havendo a apreensão de que o valor da imagem é um valor simbólico (LACAN, 1998).
    
    A chegada ao mundo comporta uma série de laços que sustentam a relação do sujeito com seu Umwelt, o mundo que o rodeia. Inicialmente, o corpo, em um estado de completa prematuração, necessita construir uma noção de si e de sua imagem a partir do que está para além de seu próprio corpo, o Outro. Este grande “Outro” abarca tudo o que é externo ao corpo e tem grande papel no que se refere aos processos de subjetivação. A identificação com a própria imagem, entrada no campo da linguagem e significações são ações que se realizam na relação do sujeito com sua alteridade, o Outro.
    
    A imagem do corpo próprio representa um elemento central na trama sociossimbólica humana. A partir das nuances e particularidades que o atravessamento do estádio do espelho comporta, é possível observar a importância do corpo para a construção do psiquismo da criança. O sujeito é capturado pela imagem do seu corpo, por esse elemento que se constitui frente ao olhar de um outro. “O corpo faz seu peso pela via do olhar”, (LACAN, [1975]2015, p. 3). Há, nesse sentido, uma exterioridade que dá notícias ao sujeito de que este corpo existe.
    
    É a partir do que o bebê, ou a criança, apreende de sua exterioridade que se forma a imagem ideal. Utilizando-se da biologia, Lacan faz uma correlação entre comportamento humano e animal para esclarecer a importância da imagem para o desenvolvimento dos indivíduos. Da mesma forma que os gafanhotos necessitam da imagem de outro gafanhoto adulto para se desenvolver, o ser-humano elege imagens ideais que darão entrada aos processos de socialização e individuação fundamentais para a gênese do Eu (SAFATLE, 2021).

    A subjetividade, portanto, constitui-se a partir do que o corpo do sujeito experimenta, abarcando sua imagem de si, imagem ideal (registro do imaginário) e na sequência a incidência da linguagem sobre este corpo. O mundo exterior que faz fronteira com cada corpo pouco tem a ver com o estímulo pulsional, isso que em cada sujeito demanda uma satisfação. A pulsão, na verdade, advém do interior do próprio organismo e dá notícias de que ali há um dizer que ecoa e ressoa através do corpo (SAFATLE, 2021).

    Através dos ecos pulsionais advindos do corpo, o sujeito se depara com a inscrição da linguagem em si, o que demarca a construção de sua dimensão simbólica com seus próprios significantes e gramática inconsciente. Dessa forma constata-se que a linguagem faz marca no corpo e media a relação homem-mundo externo. Lacan afirma que “o homem não pensa com sua alma, como o Filósofo imagina. Ele pensa porque uma estrutura, a da linguagem – como a palavra o comporta –, porque uma estrutura recorta seu corpo, e que nada tem a ver com a anatomia” (Lacan, [1974]1993 p. 18-19).

    No Seminário 3, sobre as psicoses, Lacan demarca que o inconsciente é a linguagem. Ou seja, o inconsciente carrega uma linguagem própria e primitiva. O pensamento humano, nesse sentido, é tido como a coisa que se articula na linguagem. Cada indivíduo carrega consigo seus sentidos e significados, estes que moldam sua própria visão de mundo e consequente relação com as palavras. Desta forma, a linguagem é in(corpo)rada pela vivência afetiva, social e psíquica do sujeito (LACAN, [1956] 1981).

    Chega-se à noção de que o sujeito pensa com a ajuda das palavras, e nesse laço com a linguagem se constrói uma narrativa banhada na subjetividade daquele que fala, diz e faz eco. Na Conferência sobre o Sintoma, em Genebra, Jacques Lacan diz: “se não houvesse palavras, de que poderia testemunhar o homem? É aí que ele coloca o sentido”  (LACAN, [1975]2015, p. 7).  A partir destas constatações, a palavra surge como o testemunho de um sujeito do inconsciente, um sujeito do desejo. Sujeito este que é a voz que se direciona aos ouvidos atentos e flutuantes do analista. Em psicanálise escuta-se a palavra do inconsciente.

    Lacan destaca que toda a fala possui um endereçamento, o qual direciona-se ao grande Outro que atravessa e constitui a subjetividade de cada sujeito (LACAN, [1975]2015, p. 3). Entre tropeços, sonhos e atos-falhos há notícias de um inconsciente que pulsa. Inconsciente este que não é nenhum outro senão o inconsciente freudiano. É a partir deste endereçamento e da escuta desses tropeços que um analista trabalha com seu analisando em direção à reorientação de sua palavra.

    Bibliografia utilizada:

    LACAN, J. (1956) Seminário 3, As psicoses. Rio de Janeiro: Jorge Zahar, 1981. Editor. In: https://clinicand.com/jacques-lacan-colecao-seminarios/
    LACAN, J. Conferência de Genebra sobre o sintoma (1975). In.: Campo psicanalítico - transmissão, pesquisa e clínica (2015).
    LACAN, J. (1998b). O estádio do espelho como formador da função do eu. In J. Lacan, Escritos (pp.96-103). Rio de Janeiro: Jorge Zahar Ed.
    SAFATLE, V. Introdução a Jacques Lacan. Prefácio Joel Birman. Belo Horizonte: Autêntica, 4. ed. rev atual., 2. reimp, 2021.
  `.trim();

  const articleTextThree = `
    PERSPECTIVAS DA PSICANÁLISE NO BRASIL
    ##“Tupi, or not tupi that is the question. Contra todas as catequeses. E contra a mãe dos Gracos. Só me interessa o que não é meu. Lei do homem. Lei do antropófago.”##
    &&(Oswald de Andrade)&&
    A história do Brasil carrega consigo um enlace com sucessivas violências e barbáries que marcam a população até os dias atuais. Uma história que retorna, escapa e transborda pelas rachaduras causadas por um trauma não elaborado. Seria então um caminho possível para a psicanálise brasileira, a busca de um retorno e elaboração das falhas e rupturas inscritas nesta narrativa? 
    
    A partir das construções teóricas de Freud, a psicanálise surge como uma resposta ao mal-estar que permeia a dimensão singular e coletiva da cultura. Existir na cultura, enquanto sujeito, prevê um mal-estar inevitável. Há um desconsolo que toma a dimensão social por completo (FREUD, [1930] 2010). Este mal-estar responde a uma realidade marcada pela imobilidade, onde o instituído permanece inerte e os discursos alheios são tomados como absolutos, fazendo com que imperem sempre as mesmas imagens.
    
    Cabe, neste momento, realizar um recorte da realidade brasileira. Em seu contexto atual testemunham-se cenas de um completo desenlace com a cultura. O descaso com a saúde pública, o assassinato de pessoas pretas inocentes durante operações policiais e a normalização de mais de 500 mil mortes durante a pandemia de COVID-19. Esses são apenas alguns dos elementos que compõem a lógica de violência e morte sob a qual os brasileiros vivem. Estas imagens não possuem disfarce, o traumático é posto em destaque, tornando cada vez mais difícil representar e dar sentido ao que está sendo testemunhado.
    
    O psicanalista gaúcho Edson Luiz de Sousa (2017) inicia o texto intitulado Atravessar desertos - psicanálise e utopia falando sobre os desertos que se impõem frente ao sujeito contemporâneo. O autor afirma que há um fracasso do laço social diante dos massacres humanitários e sociopolíticos que lançam a população em direção às trevas. Há portanto, um deserto de imagens impostas e caminhos exaustivamente indicados, onde regulamentos e discursos são engolidos sem nem mesmo ser reconhecida a possibilidade de tomar outra rota. 

    Estes desertos, portanto, abrigam narrativas e acontecimentos que marcam a história coletiva da população e dão corpo ao mal-estar.  Através de um retorno à história da ditadura civil-militar e suas consequências, questionam-se as violações, torturas e traumas instaurados pelo Estado. As marcas aqui citadas, são cicatrizes de uma lógica política que ainda hoje carrega a permanência da transgressão dos direitos humanos (LIMA, 2017). A realidade histórica do país entrelaça narrativas imersas em um esquecimento e não reconhecimento do sofrimento. Assim, as falhas ressurgem e se repetem insistentemente, com outras roupagens, mas ainda assim, presentes.

    Faz-se necessário, nesse sentido, o resgate de uma narrativa omitida na história brasileira, para que assim sejam produzidas representações a nível simbólico das vivências traumáticas. Vale destacar que este não reconhecimento surge como uma ameaça ao tempo presente, ou seja:
    **Aquilo que permanece como desmentido (Verleugnung) e que não se inscreve simbolicamente em um sistema representacional por meio do qual os indivíduos de uma determinada sociedade podem se fazer reconhecer uns pelos outros, retorna sistematicamente como sintoma, não apenas no corpo e no psiquismo individual, mas também no sofrimento compartilhado de uma história denegada ( LIMA, 2017, p.126).**
    O reconhecimento da história própria de uma sociedade, põe em questão a forma como este coletivo se posiciona diante dela. Existe uma complexidade expressiva no modo como cada cultura inscreve sua narrativa em uma trama sociossimbólica. Lacan aponta o meio social, como principal peça na formação da subjetivação dos sujeitos. As dinâmicas de socialização que ocorrem desde as primeiras relações com a família, até a nível de uma coletividade como o Estado, se estruturam a partir do que Lacan chamou de imagem. Esta última, é tida como um conjunto de representações ideais na rede simbólica de cada indivíduo ou cultura (SAFATLE, 2021). As representações simbólicas da cultura, assim como em outras sociedades, operam a partir de suas imagens ideais e representações, que dentro de um mesmo sistema de costumes e crenças, faz laço com a história e origens de seu povo.

    Em suas tessituras a respeito das ressonâncias do colonialismo brasileiro, com ênfase na estruturação do racismo sofrido por pessoas pretas, Lélia Gonzalez (1988/2018) dá ênfase ao fato de que em consequência do processo colonial, os brasileiros recalcaram suas origens. A população e a estrutura política em si, sofrem até hoje com os ecos dessa história manchada de sangue e apagamentos.

    Com base no que Jacques Lacan afirma sobre os processos de socialização e individuação, observa-se que as imagens ideais de um país colonizado como o Brasil, são imagens do próprio colonizador. O outro serve de referência para os modos de desejar, agir e julgar. Nesse sentido, a teoria lacaniana instaura uma clínica que abrange uma forma de crítica da alienação. O sujeito e as civilizações se estruturam a partir de uma alienação fundante, para inserir-se em uma rede simbólica, é necessário atravessar as dinâmicas de socialização e individuação que como efeito levam à alienação (SAFATLE, 2021).

    Ao contextualizar estas elaborações da teoria lacaniana com a realidade nacional, tem-se que este grande Outro é muitas vezes o estrangeiro, o que vêm de fora e não é propriamente brasileiro. Seria este outro, o responsável pela referência do que seria um modo de viver, governar, produzir e/ou mesmo do que seria um modelo de nação. O processo colonizador impôs seus próprios costumes e crenças. Há no discurso do brasileiro, independentemente de seu contexto, a fala do colono e do colonizador simultaneamente, enquanto posições subjetivas (CALLIGARIS, 2006).

    Em função dessa renúncia à própria cultura, e isso inclui até mesmo as produções artísticas, Oswald de Andrade escreve o Manifesto Antropofágico, utilizando o conceito de antropofagia para propor uma devoração do que vem de fora: “Só me interessa o que não é meu. Lei do homem. Lei do antropófago” (ANDRADE,1928, s.p.). A produção artística assume o papel de circunscrever o que há de nacional dentre as heranças da colonização. Propõe-se uma subversão dos padrões, de modo a apropriar-se do que foi imposto e devorar o que há de favorável no externo, ainda assim nos moldes de sua própria ancestralidade.

    Devorar, engolir, deglutir, para que o hostil seja ressignificado. Em Totem e Tabu, Freud (1913) argumenta que o surgimento da civilização se dá a partir da introjeção da lei, que regula a moral e elimina a barbárie. Essa introjeção é encenada através do assassinato do pai primordial, representante dessa lei, seguido da devoração de seu corpo. De maneira análoga, a antropofagia cultural convoca à devorar as vanguardas européias:
    **A luta entre o que se chamaria Incriado e a Criatura - ilustrada pela contradição permanente do homem e o seu Tabu. O amor cotidiano e o modusvivendi capitalista. Antropofagia. Absorção do inimigo sacro. Para transformá-lo em totem. A humana aventura. A terrena finalidade. Porém, só as puras elites conseguiram realizar a antropofagia carnal, que traz em si o mais alto sentido da vida e evita todos os males identificados por Freud, males catequistas (ANDRADE, 1928, s.p.).**
    A partir desta noção de antropofagia, busca-se situar a estruturação da teoria psicanalítica no Brasil. Não seria este um bom posicionamento para a psicanálise lacaniana em um país atravessado por um complexo processo colonial? Parece proporcional e cabível à história, posicionar-se frente à teoria de forma que sejam introjetadas as elaborações psicanalíticas nascidas na europa e estruturadas a partir do contexto social do próprio meio. Assim, após um detalhado retorno à Freud, a teoria lacaniana integra-se ao contexto social e político brasileiro.

    A narrativa histórica e cultural em que se inscreve o Brasil desafia o psicanalista a colocar em pauta uma discussão que o conservadorismo não ousaria. A partir destas construções, inicia-se a estruturação teórica do que costuma ser identificada como uma psicanálise brasileira. Assim como a linguagem subjetiva (lalangue) de cada sujeito que compõe um Estado, o coletivo possui sua própria rede simbólica, seus lapsos, falhas e quebras de sentido. Esta tessitura, portanto, demanda a delimitação de seus sintomas sociais, o contorno de suas falhas e traumas fazendo emergir as lógicas subversivas instauradas pela população como estratégia de sobrevivência à opressão das partes dominantes (RIVERA, 2019).

    Com relação às condições políticas da produção de conhecimento na psicanálise, esta mesma a autora afirma que se trata de uma necessidade de assumir a posição de sujeito da enunciação situado geopoliticamente (RIVERA, 2019). Ou seja, falar e produzir conhecimento a partir do contexto brasileiro em sua esfera social e política para que seja possível produzir uma psicanálise verdadeiramente brasileira, que diga do território e suas narrativas. Esta não deixa de ser uma posição subversiva diante de tantas limitações impostas pelos primeiros movimentos psicanalíticos, que ecoam até os dias atuais.

    Bibliografia utilizada:

    ANDRADE,  Oswald  de.  Manifesto  antropófago (1928).  In: ANDRADE, O. “Manifesto antropófago”. Do Pau-Brasil à Antropofagia e às Utopias. Rio de Janeiro, Civilização Brasileira, 1978.
    CALLIGARIS, C. Hello Brasil! Notas de um psicanalista europeu viajando ao Brasil. São Paulo: Escuta, 1. ed, 1991.
    FREUD, S. Freud (1930) - O mal-estar na civilização e outros textos. In: FREUD, Sigmund. Obras completas volume 18 - O Mal-estar na Civilização, Novas Conferências Introdutórias à Psicanálise e outros textos (1930-1936). Tradução de Paulo César de Souza. Rio de Janeiro: Companhia das Letras, f. 248, 496 p., 2010.
    GONZALEZ, L. Primavera para as rosas negras: Lélia Gonzalez em primeira pessoa. São Paulo: Diáspora Africana, 2018.
    LIMA, R. A. Análise Reparável e Irreparável: o Conceito Psicanalítico de Reparação na Agenda da Transição Brasileira. Psicologia: Ciência e Profissão. Brasília. Vol.37, p. 116-132, 2017.
    RIVERA, T. Uma psicanálise do outro. In: Cult, Dossiê A psicanálise no Brasil. São Paulo: Cult, v. 249, p. 16-19, 2019.
    SAFATLE, V. Introdução a Jacques Lacan. Prefácio Joel Birman. Belo Horizonte: Autêntica, 4. ed. rev atual., 2. reimp, 2021.SOUSA, Edson Luiz André de. Atravessar desertos - psicanálise e utopia. Psicol. clin. Rio de Janeiro,v. 29, n. 1, p. 23-30, 2017. Disponível em: <http://pepsic.bvsalud.org/scielo.php?script=sci_arttext&pid=S0103-56652017000100003&lng=pt&nrm=iso>. Acesso em: 5 jul. 2021.
  `.trim();

  const handleOpenModalOne = () => {
    setIsModalOneOpen(true);
  };

  const handleCloseModalOne = () => {
    setIsModalOneOpen(false);
  };

  const handleOpenModalTwo = () => {
    setIsModalTwoOpen(true);
  };

  const handleCloseModalTwo = () => {
    setIsModalTwoOpen(false);
  };

  const handleOpenModalThree = () => {
    setIsModalThreeOpen(true);
  };

  const handleCloseModalThree = () => {
    setIsModalThreeOpen(false);
  };

  const handleButtonThiago = () => {
    window.open(
      "https://www.linkedin.com/in/thiago-rocha-45b61123b/",
      "_blank"
    );
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 720;
      setIsMobile(mobile);

      const isMobile = window.innerWidth <= 720;
      if (!isMobile && isMobileNavOpen) {
        setMobileNavOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileNavOpen]);

  return (
    <>
      <div className="box">
        <FloatingButton isMobile={isMobile} />
        <section id="home">
          <div className="navbar">
            <div
              style={{
                textAlign: "center",
                flexDirection: "row",
                padding: "0 5%",
              }}
            >
              <img src={logoBranco} className="imagelogo" />
              <h4 className="titleLogo">NÁTALI WACHTMANN</h4>
            </div>
            {!isMobile && (
              <div style={{ padding: "0 5%" }}>
                <button
                  onClick={() => scrollToSection("home")}
                  className="buttonNavbar"
                >
                  Home
                </button>
                <button
                  onClick={() => scrollToSection("endereco")}
                  className="buttonNavbar"
                >
                  Endereço
                </button>
                <button
                  onClick={() => scrollToSection("servico")}
                  className="buttonNavbar"
                >
                  Serviço
                </button>
                <button
                  onClick={() => scrollToSection("sobre")}
                  className="buttonNavbar"
                >
                  Sobre
                </button>
                <button
                  onClick={() => scrollToSection("artigos")}
                  className="buttonNavbar"
                >
                  Escritos
                </button>
              </div>
            )}
            {isMobile && (
              <div style={{ padding: "0 5%" }}>
                <button className="mobileNavButton" onClick={toggleMobileNav}>
                  {isMobileNavOpen ? "✕" : "☰"}
                </button>
              </div>
            )}
            {isMobileNavOpen && (
              <div className="mobileNavOverlay">
                <div className="mobileNavContent">
                  <div className="mobileNavList">
                    <button
                      onClick={() => scrollToSection("home")}
                      className="buttonNavbar"
                    >
                      Home
                    </button>
                    <button
                      onClick={() => scrollToSection("endereco")}
                      className="buttonNavbar"
                    >
                      Endereço
                    </button>
                    <button
                      onClick={() => scrollToSection("servico")}
                      className="buttonNavbar"
                    >
                      Serviço
                    </button>
                    <button
                      onClick={() => scrollToSection("sobre")}
                      className="buttonNavbar"
                    >
                      Sobre
                    </button>
                    <button
                      onClick={() => scrollToSection("artigos")}
                      className="buttonNavbar"
                    >
                      Escritos
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!isMobile && (
            <img
              alt="Background"
              src={imageFundo}
              className="responsiveImage"
            />
          )}
          {isMobile && (
            <img
              alt="Background"
              src={imageFundoMobile}
              className="responsiveImage"
            />
          )}

          <div className="containerInfoHome">
            <div className="containerServico">
              <div className="containerTwo">
                <div className="divLeft">
                  <div className="boxDataHome">
                    <div
                      style={{
                        margin: "1vh",
                        height: "auto",
                        textAlign: "center",
                      }}
                      className="textData"
                    >
                      <img src={logoMarrom} style={{ width: "200px" }} />
                      <p>
                        <a
                          style={{
                            fontSize: "36px",
                            color: "var(--logo-title)",
                            fontFamily: "Mightiest",
                          }}
                        >
                          NÁTALI WACHTMANN
                        </a>
                      </p>
                      <p style={{ fontSize: "22px" }}>
                        Psicóloga | CRP 07/37078
                        <br />
                        Santa Maria - RS
                      </p>
                      <h4>Atendimento online e presencial</h4>
                      <button
                        className="buttonWhatsApp"
                        onClick={redirectWhatsApp}
                      >
                        <div style={{ margin: "1vh" }}>
                          <MdWhatsapp size={35} />
                        </div>
                      </button>

                      <button
                        className="buttonInstagram"
                        onClick={redirectInstagram}
                      >
                        <div style={{ margin: "1vh" }}>
                          <FaInstagram size={35} />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="divRightHome">
                  <div className="boxDataHome">
                    <div className="dataHome">
                      <img src={image} className="imageProfile" />
                      <div className="textHome">
                        Olá! Sou psicóloga formada pela Universidade Franciscana
                        (UFN). Realizo atendimento clínico de adultos e
                        adolescentes, sustentando minha prática com a teoria e
                        técnica psicanalítica. Prezo por uma posição ética e de
                        acordo com os direitos humanos.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="endereco">
          <div className="containerTwo">
            <div className="divLeftTwo">
              <div className="boxDataMap">
                <div style={{ paddingTop: "1.5vh" }} />
                <h3 className="carouselTitle">ENDEREÇO</h3>
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="textBoxDataMap">
                    <a
                      href="https://www.google.com.br/maps/place/Av.+Borges+de+Medeiros,+1680+-+Nossa+Sra.+de+F%C3%A1tima,+Santa+Maria+-+RS,+97010-070/@-29.6907252,-53.8231321,17z/data=!3m1!4b1!4m6!3m5!1s0x9503cb60800323df:0xb7510c7aaf8e8308!8m2!3d-29.6907299!4d-53.8205572!16s%2Fg%2F11c53j2yc3?entry=ttu"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Abrir no Google Maps"
                    >
                      <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
                        Avenida Borges de Medeiros, 1680, Nossa Sra. de Fátima.
                        Santa Maria - RS
                      </h4>
                    </a>
                    <div className="mapContent">
                      <MapComponent />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divRightTwo">
              <CarouselImage
                items={[
                  imageEndereco1,
                  imageEndereco2,
                  imageEndereco3,
                  imageEndereco4,
                ]}
              />
            </div>
          </div>
        </section>
        <section id="servico">
          <div className="containerServico">
            <div className="containerTwo">
              <div className="divLeft">
                <a
                  href="http://www.freepik.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={imagePsicoterapia}
                    alt="Psicoterapia"
                    className="imageGrid"
                  />
                </a>
              </div>
              <div className="divRight">
                <div className="boxDataCarousel">
                  <div style={{ paddingTop: "1.5vh" }} />
                  <h3 className="carouselTitle">NOTAS SOBRE A PSICOTERAPIA</h3>
                  <div className="textCarousel">
                    <Carousel
                      items={[
                        `Em que situações procurar? O que dizer quando chegar lá?
                      O que esperar desse processo? São muitas as dúvidas que
                      surgem ao se deparar com o desejo e/ou necessidade de
                      iniciar um processo psicoterapêutico. Por vezes, o
                      enfrentamento de situações pessoais torna-se dificultoso
                      em meio a sintomas psíquicos que causam sofrimento ao
                      sujeito e este enfrentamento demanda um acompanhamento
                      que forneça escuta clínica e um olhar técnico.`,
                        `Os atendimentos iniciam com entrevistas iniciais que
                      visam a escuta e o acolhimento da queixa principal do
                      paciente, daí vêm a famosa frase "o que te traz aqui?"
                      que os psicólogos comumente falam. Essas informações são
                      essenciais para guiar o processo terapêutico. A partir
                      daí, trabalha-se com a demanda de análise fornecida pelo
                      paciente visando o alívio dos sintomas e um
                      reposicionamento deste frente à sua história.`,
                        `A psicoterapia é um processo singular que demanda uma
                      participação ativa e conjunta do paciente juntamente com
                      seu psicólogo. Nesse caminho surgem palavras, afetos e
                      sensações que possivelmente não foram elaboradas
                      anteriormente. Por isso, é de suma importância que o
                      psicólogo esteja disposto a acolher e conduzir o
                      paciente à ressignificação de sua narrativa.`,
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="sobre">
          <div className="containerOne">
            <div className="divOne">
              <div className="boxDataCarousel">
                <div style={{ paddingTop: "1.5vh" }} />
                <h3 className="carouselTitle">EXPERIÊNCIA</h3>
                <div className="textSobre">
                  <li>
                    Sou graduada em Psicologia pela UFN (Universidade
                    Franciscana);
                  </li>
                  <li>
                    Pós-graduada em Clínica Psicanalítica Lacaniana pelo
                    Instituto ESPE;
                  </li>
                  <li>
                    Durante a formação realizei estágios nas áreas de Psicologia
                    Clínica, Serviço de Atenção Especializada e Processos
                    Grupais;
                  </li>
                  <li>
                    Atuei como psicóloga de uma ILPI (Instituição de Longa
                    Permanência);
                  </li>
                  <li>Realizo avaliações psicológicas no âmbito jurídico;</li>
                  <li>
                    Atuo como Psicóloga Clínica através do registro: CRP
                    07/37078.
                  </li>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="artigos">
          <div className="containerServico">
            <div className="containerThree">
              <div className="divOne">
                <div className="boxDataArticle">
                  <div className="dataArticle">
                    <div className="textBoxDataMap">
                      <div className="divImageArticle">
                        <img src={imagePsicanalise} className="imageArticle" />
                      </div>
                      <h3 className="titleArticle">
                        PSICANÁLISE
                        <br />
                        UMA BREVE NOÇÃO
                      </h3>
                      <div className="divTextArticle">
                        <p className="carousel-text">
                          A Psicanálise surge no século XIX a partir das
                          elaborações teóricas de Sigmund Freud acerca do
                          psiquismo humano...
                        </p>
                      </div>
                      <button
                        className="buttonArticle"
                        onClick={handleOpenModalOne}
                      >
                        Ver mais
                      </button>
                      <ArticleModal
                        isOpen={isModalOneOpen}
                        onClose={handleCloseModalOne}
                        articleText={articleText}
                        imageUrl={imagePsicanalise}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="divOne">
                <div className="boxDataArticle">
                  <div className="dataArticle">
                    <div className="textBoxDataMap">
                      <div className="divImageArticle">
                        <img
                          src={imageSubjetividade}
                          className="imageArticle"
                        />
                      </div>
                      <h3 className="titleArticle">
                        CORPO E SUBJETIVIDADE NA TEORIA DE JACQUES LACAN
                      </h3>
                      <div className="divTextArticle">
                        <p className="carousel-text">
                          Ao nascer, o sujeito apresenta uma prematuração
                          específica que está para além da formação fisiológica.
                          Há uma incompletude...
                        </p>
                      </div>
                      <button
                        className="buttonArticle"
                        onClick={handleOpenModalTwo}
                      >
                        Ver mais
                      </button>
                      <ArticleModal
                        isOpen={isModalTwoOpen}
                        onClose={handleCloseModalTwo}
                        articleText={articleTextTwo}
                        imageUrl={imageSubjetividade}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="divOne">
                <div className="boxDataArticle">
                  <div className="dataArticle">
                    <div className="textBoxDataMap">
                      <div className="divImageArticle">
                        <img src={imageBrasil} className="imageArticle" />
                      </div>
                      <h3 className="titleArticle">
                        PERSPECTIVAS DA PSICANÁLISE NO BRASIL
                      </h3>
                      <div className="divTextArticle">
                        <p className="carousel-text">
                          A história do Brasil carrega consigo um enlace com
                          sucessivas violências e barbáries que marcam a
                          população até os dias atuais...
                        </p>
                      </div>
                      <button
                        className="buttonArticle"
                        onClick={handleOpenModalThree}
                      >
                        Ver mais
                      </button>
                      <ArticleModal
                        isOpen={isModalThreeOpen}
                        onClose={handleCloseModalThree}
                        articleText={articleTextThree}
                        imageUrl={imageBrasil}
                        isMobile={isMobile}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="lowBar">
          <div className="data" style={{ textAlign: "center" }}>
            <img src={logoBranco} className="imagelogoLowBar" />
            <h4 className="titleLogo">NÁTALI WACHTMANN</h4>
          </div>
          <div className="data">
            <h4 className="title">MAPA DO SITE</h4>
            <ul className="list">
              <li>
                <button
                  onClick={() => scrollToSection("home")}
                  className="text"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("endereco")}
                  className="text"
                >
                  Endereço
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("servico")}
                  className="text"
                >
                  Serviço
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("sobre")}
                  className="text"
                >
                  Sobre
                </button>
              </li>
            </ul>
          </div>
          <div className="data">
            <h4 className="title">LOCALIZAÇÃO</h4>
            <p
              className="text"
              style={{ padding: "0 15%", textAlign: "center" }}
            >
              Atendimento online para todo o Brasil e presencial em Santa Maria
              - RS.
            </p>
          </div>
          <div className="data">
            <h4 className="title">CONTATOS</h4>
            <div className="text" style={{ textAlign: "center" }}>
              <a href="mailto:wachtmann25@gmail.com" target="_blank">
                wachtmann25@gmail.com
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=55997296001"
                target="_blank"
                className="whatsAppLowBar"
              >
                (55) 99729-6001
                <MdWhatsapp style={{ marginLeft: "5px" }} size={15} />
              </a>
            </div>
            <br />
            <h4 className="title">REDES SOCIAIS</h4>
            <div className="text">
              <a href="https://www.instagram.com/psinataliw/" target="_blank">
                <FaInstagram size={21} />
              </a>
            </div>
          </div>
        </div>
        <div className="signatureLowBar">
          <div className="data">
            Psicóloga - CRP 07/37078 - Todos direitos reservados.{" "}
            {!isMobile && "Desenvolvido por"}
            <div>
              {isMobile && "Desenvolvido por"}
              <button
                className="buttonThiago"
                onClick={() => handleButtonThiago()}
              >
                Thiago Rocha
              </button>
              .
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
