import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  articleText: string;
  imageUrl: string;
  isMobile: boolean;
}

const ArticleModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  articleText,
  imageUrl,
  isMobile,
}) => {
  const modalStyle: React.CSSProperties = {
    display: isOpen ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 10000,
  };

  const formatText = (text: string) => {
    const doubleAsteriskRegex = /\*\*(.*?)\*\*/g;
    const tripleAsteriskRegex = /\#\#(.*?)\#\#/g;
    const authorRegex = /\&\&(.*?)\&\&/g;

    const formattedText: any = [];
    let match;
    let lastIndex = 0;

    const addTextWithIndent = (start: number, end: number) => {
      if (start < end) {
        formattedText.push(
          <span
            key={`text-${start}`}
            style={{ textIndent: "2em", display: "block" }}
          >
            {text.substring(start, end)}
          </span>
        );
      }
    };

    const processRegex = (regex: RegExp, style: React.CSSProperties) => {
      while ((match = regex.exec(text)) !== null) {
        addTextWithIndent(lastIndex, match.index);
        formattedText.push(
          <blockquote key={`${match.index}`} style={style}>
            {match[1]}
          </blockquote>
        );
        lastIndex = regex.lastIndex;
      }
    };

    if (!isMobile) {
      processRegex(doubleAsteriskRegex, {
        margin: "0 0 0 200px",
        fontSize: "14px",
      });
      processRegex(tripleAsteriskRegex, {
        margin: "3vh 0 0 400px",
        fontStyle: "italic",
      });
      processRegex(authorRegex, {
        display: "flex",
        fontStyle: "italic",
        justifyContent: "flex-end",
      });
    }
    else{
      processRegex(doubleAsteriskRegex, {
        margin: "0 0 0 100px",
        fontSize: "12px",
      });
      processRegex(tripleAsteriskRegex, {
        margin: "1.5vh 0 0 120px",
        fontStyle: "italic",
      });
      processRegex(authorRegex, {
        display: "flex",
        fontStyle: "italic",
        justifyContent: "flex-end",
      });
    }

    addTextWithIndent(lastIndex, text.length);
    return formattedText;
  };

  const formattedArticleText = articleText
    .split("\n")
    .map((paragraph, index) => (
      <React.Fragment key={index}>
        {index === 0 ? (
          <div className="articleTitle">{paragraph}</div>
        ) : (
          <p>{formatText(paragraph)}</p>
        )}
      </React.Fragment>
    ));

  return (
    <div style={modalStyle} onClick={onClose}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <span onClick={onClose} className="closeModal">
          &times;
        </span>
        <div style={{ margin: "5vh" }} className="articleText">
          <img src={imageUrl} alt="Article Image" className="imageArticle" />
          <br />
          <br />
          <div>{formattedArticleText}</div>
        </div>
      </div>
    </div>
  );
};

export default ArticleModal;
